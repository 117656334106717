<template>
  <div class="pcDemo">
    <div class="actions">
      <van-button  @click="handleClear">{{$t('clear')}}</van-button >
      <van-button  @click="handleUndo">{{$t('undo')}}</van-button >
      <van-button  @click="done">{{$t('finished')}}</van-button >
      <!-- <button @click="handlePreview">View PNG</button>
      <button @click="handleColor">Change Color</button> -->
    </div>
    <div class="tip">{{$t('please_sign')}}</div>
    <canvas />
  </div>
</template>

<script>
import SmoothSignature from "smooth-signature";
export default {
  name: "pcDemo",
  data() {
    return {
      openSmooth: true,
    };
  },
  props:{
    getSignPng:{
      type:Function,
      default:null
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const canvas = document.querySelector("canvas");
      const options = {
        width: Math.min(window.innerWidth, 1000),
        height: 200,
        minWidth: 2,
        maxWidth: 10,
        minSpeed: 1,
        bgcolor: '#DFE9FF'
        // color: '#1890ff',
        //bgColor: '#f6f6f6'
      };
      this.signature = new SmoothSignature(canvas, options);
    },
    handleClear() {
      this.signature.clear();
    },
    handleUndo() {
      this.signature.undo();
    },
    handleColor() {
      this.signature.color = '#' + Math.random().toString(16).slice(-6);
    },
    handlePreview() {
      const isEmpty = this.signature.isEmpty();
      if (isEmpty) {
        alert('isEmpty')
        return;
      }
      const pngUrl = this.signature.getPNG();
      console.log('pngUrl', pngUrl)
      // window.previewImage(pngUrl);
    },
    done () {
      this.getSignPng(this.signature.getPNG())
      this.signature.clear();
    }
  },
};
</script>

<style lang="less">
.pcDemo {
  button{
    border-radius: 9px;
    margin-right: 10px;
    font-size: 12px;
    height: 30px;
  }
  canvas {
    border: 2px dashed #ccc;
    cursor: crosshair;
  }
  .actions {
    margin: 30px 0;
  }
  .tip {
    color: #108eff;
  }
}
</style>